
import { Empty } from "antd";
import React  from "react";
const Home =()=>{


    return (
        <div className="homeB">

        <div className="cardInfo">
            <div style={{textAlign:'center'}}><img src="/logoopen2be.png" alt="" /></div>
            <div><h1>DAO Management Platform</h1></div>
            
            {/* <div><p>Et deserunt adipisci sed mollitia quia ut placeat velit. Est officia perferendis ut sunt laborum ut fuga amet. Non voluptatem atque est architecto omnis ea voluptates eius et nesciunt atque. Et quasi rerum et vitae laudantium aut neque galisum id perferendis tempore.
</p></div> */}


<div>
          <p>
            A DAO, or Decentralized Autonomous Organization, is an innovative
            organizational structure that operates on the principles of
            decentralization, transparency, and community governance. It
            leverages blockchain technology to facilitate trustless interactions
            and automate decision-making processes.</p>
            <p> 
            DAOs empower communities to
            collectively manage funds, make consensus-based decisions, and
            execute initiatives without relying on traditional hierarchical
            structures.
          </p>
        </div>
      </div>
    </div>

        
    );
}


export default Home;